// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-gatsby-theme-flexiblog-core-templates-product-js": () => import("./../src/@gatsby-theme-flexiblog/core/templates/product.js" /* webpackChunkName: "component---src-gatsby-theme-flexiblog-core-templates-product-js" */),
  "component---src-gatsby-theme-flexiblog-core-templates-collection-productcategory-js": () => import("./../src/@gatsby-theme-flexiblog/core/templates/collection.productcategory.js" /* webpackChunkName: "component---src-gatsby-theme-flexiblog-core-templates-collection-productcategory-js" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-gatsby-theme-flexiblog-shared-pages-404-jsx": () => import("./../src/@gatsby-theme-flexiblog/shared/pages/404.jsx" /* webpackChunkName: "component---src-gatsby-theme-flexiblog-shared-pages-404-jsx" */),
  "component---packages-shared-src-pages-authors-jsx": () => import("./../../packages/shared/src/pages/authors.jsx" /* webpackChunkName: "component---packages-shared-src-pages-authors-jsx" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-private-label-js": () => import("./../src/pages/private-label.js" /* webpackChunkName: "component---src-pages-private-label-js" */),
  "component---src-pages-services-contract-manufacturing-js": () => import("./../src/pages/services/contract-manufacturing.js" /* webpackChunkName: "component---src-pages-services-contract-manufacturing-js" */),
  "component---src-pages-services-custom-formulation-js": () => import("./../src/pages/services/custom-formulation.js" /* webpackChunkName: "component---src-pages-services-custom-formulation-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-private-label-js": () => import("./../src/pages/services/private-label.js" /* webpackChunkName: "component---src-pages-services-private-label-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

