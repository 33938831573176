import React from 'react'
import { Box } from 'theme-ui'
import { HeaderLogo } from '../Header/Header.Logo'
import Divider from '@components/Divider'

const styles = {
  logo: {
    pb: 1,
    mb: 2,
    mt: [4, 0]
  },
  copyright: {
    pt: 2,
    mb: [2, 4],
    a: {
      color: 'grayDark'
    }
  }
}

export const FooterLogo = () => (
  <>
    <Box sx={styles.logo}>
      <HeaderLogo />
    </Box>
    <Box sx={styles.copyright}>
      © {new Date().getFullYear()}{' '}
      <a href='https://pac-trade.com' target='_blank' rel='noopener noreferrer'>
        Pack Trading Group, Inc
      </a>{' '}
      dba Pacific Beauty.
      <Divider space={1} />
      8911 Lakewood Dr Ste 22B
      <br />
      Windsor, CA 95492 USA
      <Divider space={2} />
      <a href='mailto:info@pac-beauty.com'>info@pac-beauty.com</a>
      <Divider space={1} />
      <a href='tel:+1 707-234-8221'>+1 (707) 234-8221</a>
    </Box>
  </>
)
