import React from 'react'
import { Box } from 'theme-ui'
import Navigation from '@components/Navigation'
import { useSiteConfig } from '@helpers'

const styles = {
  navHeader: {
    display: [`none`, `block`]
  }
}

export const FooterMenu = () => {
  const { footerMenu } = useSiteConfig()

  return (
    <>
      {footerMenu.map((menu, index) => (
        <Box key={'box'+index}>
          <Navigation
            key={'nav'+index}
            variant={[`horizontal`, `vertical`]}
            headingProps={{ variant: 'h4', as: 'p', sx: styles.navHeader }}
            items={[menu]}
          />
        </Box>
      ))}
    </>
  )
}
