import { useStaticQuery, graphql } from 'gatsby'

export const useProductCategories = () => {
  const { allWcProductsCategories } = useStaticQuery(categoriesQuery)
  const cats = allWcProductsCategories.nodes
  const slugCats = []

  for (let i = 0; i < cats.length; i++) {
    const cat = cats[i]
    slugCats.push({name: cat.name, slug: '/private-label/' + cat.slug + '/'})
  }

  return slugCats
    
}

const categoriesQuery = graphql`
  query allProductCategoryQuery {
    allWcProductsCategories(
      filter: {
        name: {
          nin: [
            "Skin Essentials"
            "Treatments"
            "Specialty Products"
            "Professional Use"
          ]
        }
        wordpress_id: { nin: [96, 97, 98, 99] }
        count: { gt: 0 }
      }
    ) {
      nodes {
        name
        slug
      }
    }
  }
`
