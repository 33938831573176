import tag from './tag'
import tagWhite from './tag-white'
import tagDark from './tag-dark'
import tagPrimary from './tag-primary'

export default {
  tag,
  'tag-white': tagWhite,
  'tag-dark': tagDark,
  'tag-primary': tagPrimary
}
