import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Logo from '@components/Logo'
import { useSiteConfig } from '@helpers'

export const HeaderLogo = ({ ...props }) => {
  const { title } = useSiteConfig()

  const { logo } = useStaticQuery(logoQuery)
  const { fixed } = logo && logo.childImageSharp

  return <Logo fixed={fixed} title={title} {...props} />
}

const logoQuery = graphql`
  query LogoQuery2 {
    logo: file(absolutePath: { regex: "/logo.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fixed(width: 225, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
